import 'libraries'
import 'controllers'
import 'channels'

console.log('application root loaded');
// var fancybox = function(){
//   console.log('fancybox from application.js');
//   // $(".fancybox").fancybox();
//   $('[data-fancybox="gallery"]').fancybox({
//   });
// };


// $(document).on('ready turbo:load turbo:render', function (event) {
//   console.log('fancybox loaded ready turbo:load turbo:render');
//   fancybox();

// });

// // $(document).on('ready turbo:load turbo:render', function (event) {
// //   console.log('fancybox loaded ready ');
// //   $(".fancybox").fancybox(parent: 'body');
// // });

