import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from "stimulus_reflex";
import consumer from '../channels/consumer'
import controller from './application_controller'
import debounced from 'debounced'

const application = Application.start()

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading";
// eagerLoadControllersFrom("controllers", application);

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

// initialize StimulusReflex w/top-level await
// const consumer = await cable.getConsumer()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { controller, debug: true, isolate: true })
application.consumer = consumer
// StimulusReflex.initialize(application)
// , { consumer, controller, isolate: true })
// StimulusReflex.debug = process.env.RAILS_ENV === 'development'

debounced.initialize({ input: { wait: 2500 } })
console.log('index loaded');